import { makeStyles } from "@mui/styles"
import { Paper, Typography, Link } from "@mui/material"
import { styled, experimental_sx as sx } from "@mui/system"

export const StyledTypography = styled(Typography)(
  sx({
    color: "#000000DE",
    fontSize: "0.9rem"
  })
)
export const StyledLink = styled(Link)(
  sx({
    color: "#e61e8f",
    cursor: "pointer",
    textDecoration: "underline"
  })
)
export const StyledQuote = styled(Typography)(
  sx({
    fontSize: "32px",
    color: "#221133"
    // fontFamily: "cursive"
  })
)
export const StyledQuote1 = styled(Typography)(
  sx({
    fontSize: "32px",
    color: "#221133",
    // fontFamily: "cursive",
    textAlign: "right",
    marginRight: "10px"
  })
)
export const StyledCaption = styled(Typography)(sx({}))
export const PaperDiv = styled("div")(
  sx({
    display: "flex",
    flexDirection: "column"
  })
)
export const PaperDiv1 = styled("div")(
  sx({
    display: "flex",
    alignItems: "center"
  })
)
export const PaperDiv2 = styled("div")(
  sx({
    display: "flex",
    alignItems: "flex-end"
  })
)
export const MainPaper = styled(Paper)(
  sx({
    padding: "1.2rem",
    borderRadius: "1rem",
    alignItems: "start",
    width: { xs: "250px", md: "250px", lg: "300px", xl: "350px" },
    overflowX: "auto"
  })
)
export const StyledDiv = styled("div")(
  sx({
    display: "flex",
    justifyContent: "center",
    position: "relative",
    width: { md: "100%", xl: "100%", sm: "100%", lg: "100%" },

    left: "50%"
  })
)
export const Styledpaper = styled(Paper)(
  sx({
    height: 300,
    width: 300,
    position: "relative",

    borderRadius: "10px",
    "&:hover": {
      elevation: "19%",
      height: 400,
      cursor: "pointer",
      boxShadow: 20
    }
  })
)

export const Wrapper = styled("div")(sx({ width: "90%", marginLeft: "10%", marginTop: "4%" }))
export const StyledProfile = styled("div")(
  sx({
    justifyContent: "spaced-evenly",
    alignItems: "self",

    display: "flex",
    left: "10%",
    position: "absolute",
    bottom: "40px"
  })
)
export const StyledTitle = styled("div")(
  sx({
    justifyContent: "spaced-evenly",
    alignItems: "center",

    display: "flex",
    left: "26%",
    position: "absolute",
    bottom: "40px"
  })
)
export const HeaderDiv = styled("div")(
  sx({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: { xs: "65%", md: "55%" },
    color: "#221133"
  })
)
export const MainHeaderDiv = styled("div")(
  sx({
    display: "flex",
    justifyContent: "center",
    width: "100%",
    alignItems: "center"
  })
)

export const TextLink = styled(Typography)(
  sx({
    color: "#e61e8f",
    fontSize: { xs: "0.5rem", sm: "1rem", md: "1rem" },
    textDecoration: "underline"
  })
)
const useStyles = makeStyles({
  root: {},
  companyname_typography: {
    color: "black",
    textAlign: "center",
    // fontFamily: "san-serif",
    fontStyle: "italic"
  },
  paper: {
    height: 400,
    width: 300,

    borderRadius: "10px",
    "&:hover": {
      elevation: "19%",
      height: 400,
      cursor: "pointer",
      boxShadow: 20
    }
  },
  wrapper: {
    width: "90%",
    marginLeft: "10%",
    marginTop: "4%"
  },
  quote: {
    color: "#221133"
  },
  profile: {
    marginTop: "30%",
    justifyContent: "spaced-evenly",
    alignItems: "self",

    display: "flex"
  }
})
export default useStyles
