import Link from "next/link"
import Image from "next/image"

export default function Intro() {
  return (
    <div className="section section--bg-world">
      <div className="wrapper">
        <div className="intro">
          <div className="intro__description">
            <div className="intro__headline">
              Kıbrıs&lsquo;ta İşgücü var. İş Arayan da var. <br></br>İş Kıbrıs olarak KÖPRÜ olmaya geldik.
            </div>
            <p className="intro__text">
              İş Kıbrıs, Kıbrıs&lsquo;ta en büyük sorunlardan birini çözmek için kolları sıvadı. İş arayan ve işverenleri buluşturabilmek için onlarca teknoloji ve yazılım bu
              amaçla geliştidi, yönetiyor.
            </p>
            <div>
              <Link href="https://www.innoviadigital.com">
                <div className="button" style={{ marginTop: "10px" }}>
                  Biz kim miyiz?
                </div>
              </Link>
            </div>
          </div>
          <div className="intro__image">
            <Image src="/iskibris-ss.png" alt="me" width="500" height="275" />
          </div>
        </div>
      </div>
    </div>
  )
}
