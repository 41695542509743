import React from "react"
import Head from "next/head"
import Header from "../components/Header"
import Hero from "../components/Hero"
import Intro from "../components/Intro"
import Footer from "../components/Footer"
// import Articles from "../components/Articles"
import HowItWorks from "../components/How-It-Works"
import Features from "../components/Features"
import { fetchEmployersAPI } from "../lib/api"
import MiniInfographic from "../components/MiniInfographic"
import Pricing from "../components/pricing"
import OurClients from "../components/OurClients"
import CookiesManager from "../components/CookiesManager"
import CustomTestimonials from "../components/CustomTestimonials/CustomTestimonials"

import { fetchData } from "../utils/data"

type Props = {
  articles: any
  categories: any
  packages: any
  clients: any
  testimonials: any
  statistics: any
  packagesError?: boolean
  statisticsError?: boolean
}

export default function Home(props: Props) {

  const [prPackages, setPrPackages] = React.useState(props.packages)
  const [prStatistics, setPrStatistics] = React.useState(props.statistics)

  React.useEffect(( ) => {

    if(props.statisticsError){
      fetchEmployersAPI("/api/employers/statistics")
        .then(data => {
          setPrStatistics(data)
        })
        .catch(error => {
          console.log("Error fetching statistics: ", error)
        })
    }

    if(props.packagesError){
      fetchEmployersAPI("/api/employers/packages")
        .then(data => {
          let packages = data
          packages = packages.filter(pkg => !pkg.is_hidden)
          setPrPackages(packages)
        })
        .catch(error => {
          console.log("Error fetching packages: ", error)
        })
    }

  }, [props.packagesError, props.statisticsError])

  return (
    <div>
      <Head>
        <title>İş Kıbrıs - İşverenler İçin</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <div className="container">
        <main>
          <Header />
          <Hero />
          <Intro />
          <HowItWorks />
          <Features />
          <MiniInfographic statistics={prStatistics} />
          <CustomTestimonials testimonials={props.testimonials} isClassic={false} quantity={3} showPagination={false} />
          <Pricing packages={prPackages} />
          <OurClients clients={props.clients} />
          {/* <Articles articles={props.articles} /> */}
          <CookiesManager />
        </main>
        <Footer />
      </div>
    </div>
  )
}

export async function getStaticProps() {
  // Run API calls in parallel

  let [packages, statistics] = [[], []]

  let packagesError = false;
  let statisticsError = false;

  try {
    [packages, statistics] = await Promise.all([fetchEmployersAPI("/api/employers/packages"), fetchEmployersAPI("/api/employers/statistics")])
    packages = packages.filter(pkg => !pkg.is_hidden)
  } catch (error) {
    console.log("Error fetching packages and statistics: ", error)
    packagesError = true;
    statisticsError = true;
  }
  
  const { articles, categories, clients, testimonials } = fetchData()

  return {
    props: { articles, categories, packages, clients, testimonials, statistics, packagesError, statisticsError },
    revalidate: 60
  }
}
