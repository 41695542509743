import React, { useRef } from "react"
import CookieConsent, { Cookies } from "react-cookie-consent"
import Typography from "@mui/material/Typography"

type Props = {}

export default function CookiesManager({}: Props) {
  const [cookieTimeout, setCookieTimeout] = React.useState<any>()

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setCookieTimeout(true)
    }, 4000)
    return () => clearTimeout(timer)
  }, [])

  return (
    <div style={{ transitionDelay: "10s" }}>
      {cookieTimeout ? (
        <CookieConsent
          location="bottom"
          buttonText="Kabul Et"
          declineButtonText="Reddet"
          cookieName="iskibriscookies"
          containerClasses="cookieanimation"
          contentClasses="cookieanimation"
          style={{ background: "#221133", width: "100%" }}
          overlay={true}
          buttonStyle={{ color: "white", background: "#a30e62", fontSize: "13px" }}
          enableDeclineButton
          declineButtonStyle={{ color: "white", background: "#a30e62", fontSize: "13px" }}
          overlayStyle={{ transitionDelay: "4s", transition: "width 4s" }}
          overlayClasses="cookieanimation"
          expires={150}
        >
          <Typography align="center"> Bu web sitesi, kullanıcı deneyimini geliştirmek için çerezleri kullanır.</Typography>
        </CookieConsent>
      ) : (
        ""
      )}
    </div>
  )
}
