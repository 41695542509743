import { useState, useEffect, useContext } from "react"
import Modal from "@material-ui/core/Modal"
import ApplicationForm from "./application-form"
import AppStateContext from "../context/AppStateContext"
import { Backdrop, Button, Fade } from "@material-ui/core"
import Cookies from "js-cookie"
import BusinessAccountForm from "../components/business-account-form"

export default function Hero() {
  const [overlayOpen, setOverlayOpen] = useState(false)
  const [logged, setlogged] = useState(false)
  const cookie_auth = Cookies.get("auth_token")
  //add cookie auth here as well
  const { AuthState } = useContext(AppStateContext)
  
  useEffect(() => {
    if (cookie_auth == undefined || cookie_auth == null) {
      setlogged(false)
    } else {
      setlogged(true)
    }
  }, [cookie_auth])
  //displayed when not logged in
  const designbutton = () => {
    return (
      <button onClick={e => setOverlayOpen(true)} className="button button--pink">
        Hemen başvur!
      </button>
    )
  }

  return (
    <div className="large-hero">
      {/* <picture> */}
      {/* <source media="(min-width:1380px)" srcSet="assets/images/hero--large.jpg 1920w, assets/images/hero--large-hi-dpi.jpg 3840w" />
        <source media="(min-width:990px)" srcSet="assets/images/hero--medium.jpg 1380w, assets/images/hero--medium.jpg 2760w" />
        <source media="(min-width:640px)" srcSet="assets/images/hero--small.jpg 990w, assets/images/hero--small-hi-dpi.jpg 1980w" /> */}
      <img src="/ezgif.com-gif-maker-scaled.webp" alt="Coastal view of oceans and mountains" className="large-hero__image" />
      {/* </picture> */}
      <div className="wrapper">
        <div className="large-hero__container">
          <div className="large-hero__text-content">
            <div className="large-hero__subtitle">Kuzey Kıbrıs&lsquo;ın Modern İş Arama Portalı</div>
            <h1 className="large-hero__title">İşverenler! İş İlanlarınıza Çokça Başvuru Alın</h1>

            <div className="large-hero__cta">{designbutton()}</div>
          </div>
          <div className="large-hero__form-content">{AuthState.isLoggedIn ? <BusinessAccountForm /> : <ApplicationForm />}</div>
          {/* <BusinessAccountForm /> */}
          {overlayOpen && (
            <Modal
              open={open}
              className="modal"
              onClose={e => setOverlayOpen(false)}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{ timeout: 5000 }}
            >
              <Fade in={open}>
                <div className="modal__paper" style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                  <div className="modal__header-container">
                    <Button onClick={e => setOverlayOpen(false)}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" width={24} height={24}>
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </Button>
                  </div>
                  {AuthState.isLoggedIn ? <BusinessAccountForm /> : <ApplicationForm />}
                </div>
              </Fade>
            </Modal>
          )}
          {/* {overlayOpen ? (
            <div className="large-hero__overlay">
              <div className="large-hero__overlay-header">
                <button
                  className="large-hero__overlay-close"
                  onClick={(e) => setOverlayOpen(false)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
              <div className="large-hero__overlay-inner">
                <ApplicationForm />
              </div>
            </div>
          ) : null} */}
        </div>
      </div>
    </div>
  )
}
