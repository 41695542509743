export default function MiniInfographic({statistics}) {
  return (
    <div className="section section--dark-blue infographic__outer">
      <div className="wrapper">
        <div className="row">
          <div className="row__medium-3">
            <div className="data">
              <div className="data__value">{`${statistics?.users ?? 56500}`}+</div>
              <div className="data__title">KULLANICI SAYISI</div>
            </div>
          </div>{" "}
          <div className="row__medium-3">
            <div className="data">
              <div className="data__value">{statistics?.jobs ?? 12000}+</div>
              <div className="data__title">MÜNHAL SAYISI</div>
            </div>
          </div>{" "}
          <div className="row__medium-3">
            <div className="data">
              <div className="data__value">{statistics?.applications ?? 325000}+</div>
              <div className="data__title">BAŞVURU SAYISI</div>
            </div>
          </div>{" "}
          <div className="row__medium-3">
            <div className="data">
              <div className="data__value">{statistics?.companies ?? 900}+</div>
              <div className="data__title">TİCARİ KULLANIM</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
