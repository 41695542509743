import Slider from "react-slick"

export default function OurClient({ clients }) {
  var settings = {
    infinite: true,
    autoplaySpeed: 2000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  }
  return (
    <div id="testimonials" className="section">
      <div className="wrapper">
        <div className="headline headline--centered">Referanslarımız</div>
        <div className="slider">
          <Slider {...settings}>
            {clients.map((client, i) => (
              <div className="slider__item" key={client.logo.id}>
                <img src={client.logo.url} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <hr style={{ borderColor: "white" }} />
    </div>
  )
}
