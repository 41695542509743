export default function HowItWorks() {
  return (
    <div className="section section--white">
      <div className="wrapper">
        <h6>SİSTEM NASIL ÇALIŞIYOR?</h6>
        <h2>
          İş Kıbrıs ile Çalışırken <br />
          Nasıl Başvuru Alıyoruz?
        </h2>
        <div className="row">
          <div className="row__medium-3">
            <div className="icon-text">
              <div className="icon-text__icon">1</div>
              <div className="headline headline--small">Üye Olun</div>
              İş Kıbrıs üyeliğinizi yukardaki başvuru formunu doldurarak başlatabilirsiniz. Aşağıdaki paketlerimizden birini satın alarak ilan vermeye başlayabilirsiniz.
            </div>
          </div>
          <div className="row__medium-3">
            <div className="icon-text">
              <div className="icon-text__icon">2</div>
              <div className="headline headline--small headline--centered">İş İlanı Yayınlayın</div>
              İlanınızı online olarak kolaylıkla yaklaşık 3dk içinde yayınlayabilirsiniz.
            </div>
          </div>
          <div className="row__medium-3">
            <div className="icon-text">
              <div className="icon-text__icon">3</div>
              <div className="headline headline--small">Başvuru Toplayın</div>
              İlanlarınıza gelen başvurular size email ile gönderilecektir. İlaveten, Aday Yönetimi yazılımımız ile bu başvruları filtreleyebilir ve bütün başvuru sürecini
              yönetebilirsiniz.
            </div>
          </div>
          <div className="row__medium-3">
            <div className="icon-text">
              <div className="icon-text__icon">4</div>
              <div className="headline headline--small">Aradığınız Kişileri Bulun</div>
              Uygun adayları iş görüşmesine davet edin ve aradığınız elemana hızlı ve kolay bir şekilde erişin.
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
