import React, { useState } from "react"
import { Avatar } from "@mui/material"
import useStyles, {
  StyledTypography,
  StyledQuote,
  StyledDiv,
  PaperDiv,
  PaperDiv1,
  StyledQuote1,
  PaperDiv2,
  MainPaper,
  StyledLink,
  HeaderDiv,
  TextLink,
  MainHeaderDiv
} from "./Styles/styles"
import { useRouter } from "next/router"
import Pagination from "@mui/material/Pagination"
import Stack from "@mui/material/Stack"

type Props = {
  testimonials: any
  quantity: number
  isClassic?: boolean
  showPagination: boolean
}

const CustomTestimonials: React.FC<Props> = ({ testimonials, isClassic, quantity, showPagination }) => {
  const classes = useStyles()
  const [testimonialitems, setTestimonialitems] = useState(testimonials)
  const route = useRouter()
  const recenttestimonials = testimonialitems.slice(0, quantity)
  const [page, setPage] = React.useState(recenttestimonials)
  const [first, setFirst] = useState(1)
  let pages
  //row perpage
  const [rowsPerPage, setRowperPage] = useState(0)

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, testimonials.length - page * rowsPerPage)
  const handleChange = (event, value) => {
    const recenttestimonials = testimonialitems.slice(value * quantity - quantity, quantity * value)
    setPage(recenttestimonials)
    setFirst(value)
  }
  const [rows, setrows] = useState([recenttestimonials])
  const maxPage = Math.ceil(testimonials.length / 6)
  const minPage = 1

  const customgrid = ["span 20", "span 22", "span 23", "span 23", "span 22", "span 20"]

  return (
    <div id="testimonial" className="section section--bg-world section--big" style={{ background: "whitesmoke" }}>
      <MainHeaderDiv>
        <HeaderDiv className="testimonials-header__headline">
          <div className="wrapper">
            <div className="headline headline--gray headline--centered">REFERANS</div>

            <div className="pricing__headline headline headline--centered">Şirketler iskibris sever</div>
          </div>
          {!showPagination && (
            <StyledLink
              onClick={() => {
                route.push("/testimonials")
              }}
            >
              <TextLink>Hepsini gör</TextLink>{" "}
            </StyledLink>
          )}
        </HeaderDiv>
      </MainHeaderDiv>

      <div className="custom-wrapper">
        <div className="row  row--action header__top--height-reset">
          <div className="custom-wrapper header__top">
            <Stack spacing={2}>
              <StyledDiv>
                <div className="custom-wrapper__wrappergrid">
                  {page.map((item, i) => {
                    return (
                      <div key={item.id} style={{ padding: "0.2rem", position: "relative", display: "grid", gridRowEnd: isClassic ? customgrid[i] : "" }}>
                        <MainPaper key={item.id}>
                          <PaperDiv>
                            <div>
                              <PaperDiv1>
                                <Avatar sx={{ width: 24, height: 24 }} src={item?.company_image.url} />
                                &nbsp;&nbsp;
                                <StyledTypography variant="h6">{item.company_name}</StyledTypography>
                              </PaperDiv1>
                              <span>
                                <StyledQuote>&#8220;</StyledQuote>
                              </span>
                              <div>
                                <StyledTypography>{item.text}</StyledTypography>
                              </div>

                              <span>
                                <StyledQuote1>&#8221;</StyledQuote1>
                              </span>
                            </div>
                            <PaperDiv2>
                              <div>
                                <Avatar src={item.author_image.url}></Avatar>
                              </div>
                              <div style={{ marginLeft: "0.5rem" }}>
                                <StyledTypography variant="body1">{item.author_name}</StyledTypography>
                                <StyledTypography variant="body2">{item.author_title}</StyledTypography>
                              </div>
                            </PaperDiv2>
                          </PaperDiv>
                        </MainPaper>
                      </div>
                    )
                  })}
                </div>
              </StyledDiv>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {showPagination ? <Pagination count={Math.ceil(testimonials.length / quantity)} page={first} onChange={handleChange} /> : ""}
              </div>
            </Stack>
          </div>
        </div>
      </div>
    </div>
  )
}
export default CustomTestimonials
