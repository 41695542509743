export default function Features() {
  return (
    <div id="features" className="section section--gray">
      <div className="wrapper">
        <h2 className="section-title generic-content-container">
          <div className="headline headline--centered">Özelliklerimiz</div>
        </h2>
        <div className=" generic-content-container features">
          <div className="feature-item__container">
            <div className="feature-item">
              <img className="feature-item__icon" src="/isverene_ozel_sayfa.png" />
              <h3 className="feature-item__title">Email Üzerinden Değerlendirme</h3>
              <p className="feature-item__paragraph">
                Size başvuran her bir aday için size bir email gönderilmektedir. Bu email içerisinden adaylarınız hakkında hızlı bır geri bildirim vererek, pratik bir şekilde aday
                filtrelemesi yapabilirsiniz.
              </p>
            </div>
          </div>
          <div className="feature-item__container">
            <div className="feature-item">
              <img className="feature-item__icon" src="/toplu_mesaj_logo.png" />
              <h3 className="feature-item__title">Adaylara Toplu Mesaj Gönderimi</h3>
              <p className="feature-item__paragraph">
                Adaylarınıza yapacağınız bilgilendirmeler için toplu mesaj sistemimiz sayasinde bu en zor süreci sadece 1 dakika içerisinde gerçekleştirebilirsiniz.{" "}
              </p>
            </div>
          </div>

          <div className="feature-item__container">
            <div className="feature-item">
              <img className="feature-item__icon" src="/isverene_ozel_sayfa.png" />
              <h3 className="feature-item__title">İşverene Özel Sayfa</h3>
              <p className="feature-item__paragraph">
                Aday Yönetim Yazılımımız ile tüm başvuru sürecini, kolaylıkla yönetebileceksiniz. İşe alım aşamalarını kolaylıkla değiştirebilecek, adaylar üzerindeki görüş ve
                notlarını pratik bir şekilde iş arkadaşlarınız ile paylaşabileceksiniz.
              </p>
            </div>
          </div>
          <div className="feature-item__container">
            <div className="feature-item">
              <img className="feature-item__icon" src="/yapay_zeka_logo.png" />
              <h3 className="feature-item__title">Yapay Zeka ile Kolay Aday Arama</h3>
              <p className="feature-item__paragraph">
                Gününüzün önemli bir kısmı adayları aramak ile geçtiğinin farkında olarak, bu süreci kolaylaştırmak için yapay zeka teknolojilerinden de yararlanarak ufak tefek
                yazım hatalarında dahi, ya da başka kriterlerden aradığınız adayı kolaylıkla bulabilirsiniz.
              </p>
            </div>
          </div>
          <div className="feature-item__container">
            <div className="feature-item">
              <img className="feature-item__icon" src="/ise_alim.png" />
              <h3 className="feature-item__title">İşe Alım Yazılımı</h3>
              <p className="feature-item__paragraph">
                Aday Yönetim Yazılımımız ile tüm başvuru sürecini, kolaylıkla yönetebileceksiniz. İşe alım aşamalarını kolaylıkla değiştirebilecek, adaylar üzerindeki görüş ve
                notlarını pratik bir şekilde iş arkadaşlarınız ile paylaşabileceksiniz.
              </p>
            </div>
          </div>
          <div className="feature-item__container">
            <div className="feature-item">
              <img className="feature-item__icon" src="/arayuz_logo.png" />
              <h3 className="feature-item__title">Basit, Anlaşılır, İnteraktif Arayüz</h3>
              <p className="feature-item__paragraph">
                Basit, anlaşılır ve son derecek interaktif arayüzümüzü çok seveceksiniz. Üstelik, odak grubumuz ile sürekli güncelliyor geliştiriyoruz. Sizin de önerileriniz olursa
                dinlemeye hazırız.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
