import React from "react"
import ReactMarkdown from "react-markdown"
import Link from "next/link"

import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import CheckIcon from "@mui/icons-material/Check"

import tagGtm from "../hooks/useGtm"

export default function Pricing({ packages }) {
  const [orderedItems] = React.useState(packages.sort((a, b) => a.order < b.order))
  const colors = ["#505050", "black", "#505050", "#FF8042"]
  const marginTop = ["25%", "15%", "3%"]

  const height = [500, 520, 500]
  const marginTops = ["6%", "0%", "6%"]
  const elevations = [3, 20, 3]
  const border = ["none", "2px solid ", "none"]
  const pricingbordercolor = ["none", "#a30e62", "none"]
  const pricingbackgroundcolor = ["none", "#a30e62", "none"]
  const pricingColorvariant = ["outlined", "contained", "outlined"]
  const pricingButtonColor = ["#a30e62", "white", "#a30e62"]
  const pricingTextBorderColor = ["#a30e62", "#a30e62", "#a30e62"]
  const width = [280, 280, 280]
  const widths = [280, 280, 550]

  const [pageLoading, setPageLoading] = React.useState(true)

  React.useEffect(() => {
    if (!pageLoading) {
      const { tagEvent } = tagGtm()
      tagEvent({ event: "view_item_list", data: { item_list_name: "packages_list", item_list_id: "packages_list", items: packages } })
    }
  }, [pageLoading])

  React.useEffect(() => {
    if (pageLoading) {
      setPageLoading(false)
    }
  }, [])

  const singlePackageItem = label => (
    <div className="single-feature">
      <CheckIcon size={20} className="single-feature--icon" />
      <Typography variant="body2">{label}</Typography>
    </div>
  )

  return (
    <div id="pricing" className="section section--gray">
      <div className="wrapper">
        {/* <div className="headline headline--gray headline--centered">KASIM SONUNA KADAR</div>
        <div className="pricing__headline headline headline--centered">BLACK FRIDAY PAKETLERİMİZ %40 İNDİRİMLİ</div> */}
      <div className="headline headline--gray headline--centered">ÜCRETLENDİRME</div>
        <div className="pricing__headline headline headline--centered">Paketlerimiz</div>
        <Grid container justifyContent="center" spacing={1}>
          {orderedItems.map((singlePackage, i) => (
            <Grid key={Math.random()} item>
              <br></br>
              <Paper
                elevation={elevations[i]}
                sx={{
                  border: border[i],
                  borderColor: pricingbordercolor[i],

                  marginTop: marginTops[i],
                  height: height[i],
                  width: { xs: width[i], md: widths[i], lg: 360 },
                  backgroundColor: theme => (theme.palette.mode === "dark" ? "#1A2027" : "#fff")
                }}
              >
                <br></br>
                <Typography variant="h5" style={{ textAlign: "center", fontWeight: "bold", color: colors[i] }}>
                  {singlePackage.name}
                </Typography>
                <Typography variant="h6" style={{ textAlign: "center", fontWeight: "bold" }}>
                  {singlePackage.price} {singlePackage.currency}
                </Typography>
                <Link
                  href={"/cart?add-to-cart=" + singlePackage.handle}
                  onClick={() => {
                    const handleClick = e => {
                      e.preventDefault()
                      router.push("/cart?add-to-cart=" + singlePackage.handle)
                    }
                    handleClick
                  }}
                  passHref={true}
                >
                  <div style={{ justifyContent: "center", alignItem: "center", display: "flex" }}>
                    <Button
                      variant={pricingColorvariant[i]}
                      size="large"
                      style={{ color: pricingButtonColor[i], borderColor: pricingTextBorderColor[i], backgroundColor: pricingbackgroundcolor[i] }}
                    >
                      Paket Satın Al
                    </Button>
                  </div>
                </Link>
                <br></br>

                <div>
                  {`${singlePackage?.content ?? singlePackage?.package_details?.content}`
                    .trim()
                    .split("\n")
                    .map(item => {
                      return <React.Fragment key={item}>{singlePackageItem(item)}</React.Fragment>
                    })}
                </div>
                {/* <div className="generic-content-container pricing__details" style={{marginLeft:"10%"}}>
                <ReactMarkdown className="reactMarkDown"  source={singlePackage.content} style={{display:"list-item"}} escapeHtml={false} />
              </div> */}
              </Paper>
            </Grid>
          ))}
        </Grid>
        {/* <div
          className="flex pricing__outer"
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-evenly"
          }}
        >
          {packages.map((singlePackage, i) => {
            return (
              <div className="pricing" key={Math.random()}>
                <h3 className="pricing__title">{singlePackage.name}</h3>
                <div
                  className="pricing__photo"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  
                </div>
                <h3 className="pricing__subtitle">{singlePackage.price}</h3>
                <div className="generic-content-container pricing__details">
                  <ReactMarkdown source={singlePackage.content} escapeHtml={false} />
                </div>
                <Link href={"/offers/" + singlePackage.handle + "?id=" + singlePackage.id} passHref={false}>
                </Link>
              </div>
            )
          })}
        </div> */}
        <br></br> <br></br>
      </div>
    </div>
  )
}
